<template>
  <div>
    <div class="error-container-401" :style="'width:'+width+'px;height:'+height+'px;'">
      <div class="text-container">
        <div class="text-container-title" :style="'padding-top:'+((height/2)-20)+'px'">{{headline}}</div>
        <div class="text-container-deatil">{{info}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page402",
  data() {
    return {
      headline: "暂无权限,无法访问",
      info: "你还未此模块的访问权限！",
      width: "",
      height: "",
    };
  },
  created() {
    this.height = document.documentElement.clientHeight;
    this.width = document.documentElement.clientWidth;
  },
};
</script>

<style lang="scss" scoped>
.error-container-401 {
  background-image: url('../../assets/images/401.png');
  margin: 0px;
  background-size: 100% 100%;
}
.text-container {
  text-align: center;
  line-height: 26px;

  &-title {
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &-deatil {
    margin-left: 10%;
    width: 80%;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
  }
}
</style>
